import { List, Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField, TopToolbar, BooleanField, FilterButton } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import ParsedDateTextField from "../components/parsed_date_textfield";
import FilterTextInput from '../components/filter_textinput';
import SelectEvents from '../components/select_event';
export const PersonIcon = PermIdentity;


const credentialClaimFilters = [
  <SelectEvents source="eventIdEq" alwaysOn />,
  <FilterTextInput source="participantIdEq" alwaysOn />,
];

function CredentialClaimList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={credentialClaimFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar>
        <FilterButton/>
      </TopToolbar>}
    >
      {credentialClaimGrid}
    </List>
  );
}

const credentialClaimGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event">
      <TextField source="event" />
    </ReferenceField>
    <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.emailAddress" sortable={false}>
      <TextField source="emailAddress" />
    </ReferenceField>
    <TextField source="uuid" sortable={false} />
    <BooleanField source="issued" />
    <BooleanField source="revoked" />
    <ShowButton />
  </Datagrid>


function CredentialClaimShow(){
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event">
          <TextField source="event" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.emailAddress" sortable={false}>
          <TextField source="emailAddress" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.firstName" sortable={false}>
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.lastName" sortable={false}>
          <TextField source="lastName" />
        </ReferenceField>
        <TextField source="uuid" />
        <BooleanField source="issued" />
        <BooleanField source="revoked" />
      </SimpleShowLayout>
    </Show>
  );
}


export {CredentialClaimList, CredentialClaimShow, credentialClaimGrid, credentialClaimFilters};
